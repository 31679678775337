<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">Edit Album</p>
    </div>

    <div class="dialog-content" style="overflow-y: auto; max-height: 560px;">
      <b-container class="mt-3">
        <b-row>
          <b-col class="my-auto" cols="5">
            <croppa v-model="albumCover"
                    accept=".jpeg,.png,.jpg"
                    :width="190"
                    :height="190"
                    :placeholder="placeholder"
                    placeholder-color="#068bcd"
                    :placeholder-font-size="12"
                    :prevent-white-space="true"
                    canvas-color="#fff"
                    :show-remove-button="true"
                    remove-button-color="red"
                    :remove-button-size="25"
                    :show-loading="true"
                    :loading-size="25"
                    :initial-image="currentAlbumCover"
                    @file-type-mismatch="onFileTypeMismatch"
                    @new-image="updateImage('new')"
                    @image-remove="updateImage('removed')">
            </croppa>
            <div class="buttons">
              <i class="fas fa-search-plus icon"
                 @click="updateImage('zoomIn')">
              </i>
              <i class="fas fa-search-minus icon"
                 @click="updateImage('zoomOut')">
              </i>
            </div>
          </b-col>
          <b-col class="my-auto" cols="7">
            <label for="albumName">Album Name: </label>
            <b-form-input class="mt-2"
                    id="albumName"
                    type="text"
                    aria-describedby="album-name"
                    autocomplete="off"
                    placeholder="Enter Album Name"
                    v-model="$v.albumName.$model"
                    :state="!$v.albumName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="album-name" v-if="$v.albumName.$error">
              <span v-if="!$v.albumName.required">
                This is a required field.</span>
              <span v-if="!$v.albumName.uniqueAlbumName &&
                      $v.albumName.required">
                Album name is already used.
              </span>
            </b-form-invalid-feedback>

            <div class="d-flex justify-content-end mt-4">
              <b-button variant="outline-success"
                        :disabled="$v.$invalid || disableButton"
                        @click="prepareEdit">
                Submit
              </b-button>
              <b-button class="ml-1"
                        variant="outline-danger"
                        @click="$emit('close')">
                Close
              </b-button>
            </div>
          </b-col>
        </b-row>
        <loading :active.sync="isLoading"
                 :is-full-page="true"></loading>
      </b-container>
    </div>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  import 'vue-croppa/dist/vue-croppa.css';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name  : 'EditAlbum',
    props : {
      album : Object,
    },
    data() {
      return {
        albumName         : this.album.album_name,
        albumCover        : null,
        currentAlbumCover : this.album.album_thumbnail,
        isLoading         : false,
        placeholder       : 'Choose an image',
        progress          : 0,
        uniqueAlbumName   : true,
        disableButton     : true,
      }
    },
    validations : {
      albumName : {
        required,
        uniqueAlbumName() {
          return this.uniqueAlbumName;
        },
      },
      albumCover : {},
    },
    components : {
      Loading,
    },
    watch : {
      albumName(value) {
        if (value)
          this.checkAlbumName();
      },
    },
    methods : {

      /**
       * Check Album Name
       */
      // eslint-disable-next-line no-undef
      checkAlbumName : _.debounce(function() {
        this.$http.get('/mobile/api/album/albumname', {
          params : {
            albumName : this.albumName,
          },
        }).then(response => {
          this.uniqueAlbumName = (response.data) ? false : true;
          this.disableButton = (this.uniqueAlbumName) ? false : true;
          
          // check if entered album name is similar to current album name
          if (this.albumName === this.album.album_name) {
            // consider album name as unique but submit button is disabled
            this.disableButton = true;
            this.uniqueAlbumName = true;
          }
        });
      }, 500),

      /**
       * Prepare Edit Album Params
       */
      async prepareEdit() {
        const formData = new FormData();
        formData.append('albumName', this.albumName);
        formData.append('action', 'Update');
        formData.append('mobileAlbumId', this.album.mobile_album_id);
        formData.append('updateFields',
                        // eslint-disable-next-line camelcase
                        JSON.stringify({album_name : this.albumName}));

        if (this.albumCover) {
          // Update Album Cover and/or Album Name
          this.albumCover.generateBlob((blob) => {
            formData.append('file', blob, 'image.jpg');
            formData.append('imageType', 'album_cover');
            this.editAlbum(formData);
          }, 'image/jpeg');
        } else {
          // Update Album Name only
          this.editAlbum(formData);
        }
      },

      /**
       * Edit Album
       *
       * @param formData
       */
      async editAlbum(formData) {
        try {
          this.isLoading = true;
          const result =
            await this.$http
              .post('/api/upload/image/album/thumbnail', formData,
                    {
                      onUploadProgress : e =>
                        this.progress = Math.round(e.loaded * 100 / e.total),
                    }
              );

          if (result) {
            this.isLoading = false;
            // eslint-disable-next-line camelcase
            this.album.album_name = result.data.albumName;
            if (result.data.file)
              // eslint-disable-next-line camelcase
              this.album.album_thumbnail = result.data.file;
            
            this.$emit('close');

            setTimeout(() =>
                         this.$notify({
                           group : 'notif',
                           type  : 'success',
                           title : 'Success!',
                           text  : 'Updated Album Successfully!',
                         })
                       , 2000);
          }
        } catch (error) {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      /**
       * Image Update (Croppa) Handler
       *
       * @param state
       */
      updateImage(state) {
        switch (state) {
        case 'new':
          this.disableButton = false;
          break;
        case 'zoomIn':
          this.albumCover.zoomIn();
          this.disableButton = false;
          break;
        case 'zoomOut':
          this.albumCover.zoomOut();
          this.disableButton = false;
          break;
        case 'removed':
        default:
          this.disableButton = true;
          break;
        }
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Invalid file type.';
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/edit-album";
</style>
